@import '@aurora/shared-client/styles/_variables.pcss';

.lia-banner {
  display: flex;
  flex-direction: column;
  position: absolute;
  max-width: 310px;
  width: calc(100% - 1.75rem);
  border-radius: var(--lia-bs-border-radius-lg);
  background-color: var(--lia-bs-white);
  box-shadow: var(--lia-bs-box-shadow-lg);
  font-weight: var(--lia-bs-font-weight-normal);
  font-size: var(--lia-bs-font-size-sm);
  padding: 15px;
  align-self: center;
  text-align: center;

  @media (--lia-breakpoint-down-lg) {
    font-size: var(--lia-font-size-xs);
    padding: 10px;
  }
}

.lia-btn {
  margin-top: 15px;
  align-self: center;
}

.lia-link-icon {
  vertical-align: unset;
  margin-left: 5px;
}
